import { createInternalLink } from "~utils/link_utils/createInternalLink";

import type { ResolvedRelationData } from ".";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export function transformStoryblokEntryNodeToCarouselItem({
  fields,
  full_slug,
}: SbRelationsGraphQlNode): ResolvedRelationData {
  const link = createInternalLink(full_slug);

  const { pageMetadata } = fields || {};

  return {
    link: fields?.linkToExternalPage || link,
    title: pageMetadata?.prefix,
    summary: fields?.pageMetadata?.description,
    ctaText: pageMetadata?.ctaText,
    full_slug,
  };
}
